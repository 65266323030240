<template>
  <div class="dialog-container">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'addUpdateScreen' && warehouseForm == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title">{{ warehouseForm.ID != null ? "Warehouse ID " : "New Warehouse" }}<span class="yellow-dot">.</span>{{ warehouseForm.ID != null ? "  #" + warehouseForm.WarehouseNo : "" }}</div>
          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Warehouse Name</div>
              <el-input v-model="warehouseForm.Title" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Title", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Authorized Person</div>
              <el-input v-model="warehouseForm.AuthorizedPerson" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("AuthorizedPerson", validation) }}</span>
            </el-col>
            <el-col class="input-container email" :sm="8">
              <div class="text">Email</div>
              <el-input v-model="warehouseForm.Email" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Email", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Contact Number</div>
              <el-input v-model="warehouseForm.ContactNumber" placeholder="07776668899"></el-input>
              <span class="error-validation">{{ $errorMessage("ContactNumber", validation) }}</span>
            </el-col>
          </el-row>

          <div class="line"></div>
          <div class="title">Address<span class="yellow-dot">.</span></div>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="24">
              <div class="text">Address</div>
              <el-input v-model="warehouseForm.Address" type="textarea" :rows="5" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Address", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Country</div>
              <el-select
                v-model="warehouseForm.CountryId"
                v-if="getAllCountries"
                filterable
                placeholder="Select"
                @clear="
                  warehouseForm.CountryId = null;
                  warehouseForm.CityId = null;
                "
                clearable
              >
                <el-option v-for="(item, index) in getAllCountries" :key="item.country_id + index" :label="item.country" :value="item.country_id"> </el-option>
              </el-select>
              <span class="error-validation">{{ $errorMessage("CountryId", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">City</div>
              <el-select v-model="warehouseForm.CityId" filterable placeholder="Select" @clear="warehouseForm.CityId = null" clearable :disabled="warehouseForm.CountryId == null" v-if="getAllCountries">
                <template v-if="getAllCountries.some((x) => x.country_id == warehouseForm.CountryId)">
                  <el-option v-for="item in getAllCountries.find((x) => x.country_id == warehouseForm.CountryId).cities" :key="item.city_id" :label="item.name" :value="item.city_id"> </el-option>
                </template>
              </el-select>
              <span class="error-validation">{{ $errorMessage("CityId", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Post Code</div>
              <el-input class="postcode" v-model="warehouseForm.PostalCode" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("PostalCode", validation) }}</span>
            </el-col>
          </el-row>

          <div class="title">Notes<span class="yellow-dot">.</span></div>
          <el-input v-model="warehouseForm.Notes" type="textarea" :rows="5" placeholder="Add Note..."></el-input>
          <div class="line" v-if="warehouseForm.ID != null"></div>

          <div class="table-container" v-if="warehouseForm.ID != null">
            <div class="dialog-title">
              <div class="head-title">Stocks<span class="yellow-dot">.</span></div>
            </div>
            <div class="view-info">
              <p>You are viewing page {{ stockFilter.Page }} out of a total of {{ stockTotalCount.PageCount }} pages. (Total Records: {{ stockTotalCount.TotalCount }})</p>
              <!-- <p>You are viewing page {{ stockFilter.Page }} out of a total of {{ stockTotalCount.PageCount }} pages. (Total Records: {{ stockTotalCount.TotalCount }})</p> -->

              <el-button class="refresh-btn" @click="arrowsRotate"> <i class="icon-arrows-rotate-solid"></i></el-button>
            </div>
            <el-table :data="stockList" style="width: 100%">
              <!-- <el-table-column prop="StockNo" label="Stock Code" width="180"> </el-table-column> -->
              <el-table-column prop="Product.SKU" label="SKU" width="180"> </el-table-column>
              <el-table-column prop="Customer.CompanyName" label="Customer Name"></el-table-column>
              <el-table-column prop="Product.Title" label="Product Name"></el-table-column>
              <el-table-column prop="PalletQuantity" label="Pallet Quantity"></el-table-column>
              <el-table-column prop="BoxQuantity" label="Box Quantity"></el-table-column>
              <el-table-column label="Actions" width="160">
                <template slot-scope="scope">
                  <el-dropdown>
                    <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span class="view-edit" @click="innerDialogOpen(scope.row)"> <i class="icon-truck-arrow"></i> Transfer</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <div class="table-bottom">
              <div class="info">
                <span>Show</span>
                <el-select v-model="stockFilter.PageSize">
                  <el-option label="12" :value="12"></el-option>
                  <el-option label="24" :value="24"></el-option>
                  <el-option label="36" :value="36"></el-option>
                  <el-option label="100" :value="100"></el-option>
                  <el-option label="200" :value="200"></el-option>
                </el-select>
                <span>entries</span>
              </div>
              <el-pagination layout="prev, pager, next" :current-page.sync="stockFilter.Page" :page-size="stockFilter.PageSize" :total="stockTotalCount.TotalCount"> </el-pagination>
            </div>
          </div>
        </div>
        <div class="dialog-footer footerbutton">
          <el-button class="btn" type="danger" @click="close()"> <i class="icon-xmark-solid"></i> Cancel</el-button>
          <el-button v-if="user.Role == 10 || user.Role == 3 || user.Role == 5" class="btn" @click="addOrUpdate" :loading="loading == 'saveLoading'" :type="warehouseForm.ID != null ? 'info' : 'primary'" :icon="warehouseForm.ID != null ? 'el-icon-edit' : 'el-icon-plus'">{{ warehouseForm.ID != null ? "Edit Warehouse" : "Add New Warehouse" }}</el-button>
        </div>
        <el-dialog :visible.sync="innerDialog" append-to-body :close-on-click-modal="false" :destroy-on-close="true" custom-class="innerDialog">
          <template v-if="innerDialog">
            <div class="dialog-container">
              <div class="dialog-title">
                <div class="head-title">Stock Transfer<span class="yellow-dot">.</span></div>
                <el-button class="btn close" type="danger" @click="closeInner()"> <i class="icon-xmark-solid"></i></el-button>
              </div>
              <div class="form">
                <el-row :gutter="24">
                  <el-col class="input-container" :sm="12">
                    <div class="text">Product Name</div>
                    <el-input v-model="stockForm.ProductName" placeholder="..." disabled></el-input>
                  </el-col>
                  <el-col class="input-container email" :sm="12">
                    <div class="text">Target Warehouse</div>
                    <el-select v-model="stockForm.TargetWarehouseId" placeholder="Select" class="select" clearable>
                      <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.Title" :value="item.ID"> </el-option>
                      <el-option label="Disposal" :value="null"> </el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col class="input-container" :sm="12">
                    <div class="text">Box Quantity</div>
                    <el-input v-model="stockForm.BoxQuantity" placeholder="..."></el-input>
                  </el-col>
                  <el-col class="input-container email" :sm="12">
                    <div class="text">Pallet Quantity</div>
                    <el-input v-model="stockForm.PalletQuantity" placeholder="..."></el-input>
                  </el-col>
                </el-row>
              </div>
              <div class="dialog-footer footerbutton">
                <el-button class="btn" type="info" @click="transferWarehouseItem"> <i class="icon-check-double-solid"></i> Transfer</el-button>
                <el-button class="btn" type="danger" @click="closeInner()"> <i class="icon-xmark-solid"></i> Cancel</el-button>
              </div>
            </div>
          </template>
        </el-dialog>
      </el-row>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["id"],
  name: "warehouse-add-update",
  data() {
    return {
      warehouseForm: null,
      loading: false,
      selectedObj: null,
      timeout: null,
      innerDialog: false,
      stockForm: {
        WarehouseItemId: null,
        TargetWarehouseId: null,
        BoxQuantity: null,
        PalletQuantity: null,
        ProductName: null,
      },
    };
  },
  watch: {
    stockFilter: {
      deep: true,
      async handler() {
        await this.$store.dispatch("getStockList");
      },
    },
  },
  async created() {
    await this.checkedId();
    await this.$store.dispatch("getStockList");
    await this.$store.dispatch("getWarehouseList");
    await this.$store.commit("setWarehouseValidation", []);

    if (this.id) {
      await this.setForm();
    } else {
      this.warehouseForm = {
        ID: null,
        WarehouseNo: null,
        AuthorizedPerson: null,
        Email: null,
        ContactNumber: null,
        CountryId: 2635167,
        CityId: null,
        PostalCode: null,
        Address: null,
        Notes: null,
        Title: null,
      };
    }
    await this.setNumber();
  },
  beforeDestroy() {
    this.warehouseForm = {
      ID: null,
      WarehouseNo: null,
      AuthorizedPerson: null,
      Email: null,
      ContactNumber: null,
      CountryId: 2635167,
      CityId: null,
      PostalCode: null,
      Address: null,
      Notes: null,
      Title: null,
    };
  },
  methods: {
    innerDialogOpen(obj) {
      this.stockForm.ProductName = obj.Product.Title;
      this.stockForm.WarehouseItemId = obj.ID;
      this.innerDialog = true;
    },
    async getStockList() {
      console.log("stok");
      var payload = { WarehouseId: this.warehouseForm.ID, CustomerId: this.warehouseForm.CustomerId, Page: 1, PageSize: 12 };
      var res = await this.$store.dispatch("getStockList", payload);
      if (!res.data.HasError) {
        this.stockList = res.data.Data.Items;
      }
    },
    async arrowsRotate() {
      await this.getStockList();
    },
    async setForm() {
      this.warehouseForm = await this.$store.dispatch("getOneWarehouse", this.id);
      await this.getStockList();
    },
    setNumber() {
      if (this.warehouseForm.ContactNumber) {
        this.CountryCode = this.warehouseForm.ContactNumber.slice(0, -10);
        this.CustomNumber = this.warehouseForm.ContactNumber.slice(-10);
      } else {
        this.CountryCode = null;
        this.CustomNumber = null;
      }
    },
    close(id) {
      this.$emit("close", id);
    },
    closeInner() {
      this.innerDialog = false;
      this.stockForm = {
        WarehouseItemId: null,
        TargetWarehouseId: null,
        BoxQuantity: null,
        PalletQuantity: null,
      };
    },
    async transferWarehouseItem() {
      var payload = {
        WarehouseItemId: this.stockForm.WarehouseItemId,
        TargetWarehouseId: this.stockForm.TargetWarehouseId,
        BoxQuantity: this.stockForm.BoxQuantity,
        PalletQuantity: this.stockForm.PalletQuantity,
      };
      var res = await this.$client.post("/Warehouse/TransferWarehouseItem", payload);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
      }
    },

    async addOrUpdate() {
      this.$store.commit("setWarehouseValidation", []);
      if (this.validation.length > 0) {
        return;
      }

      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("AddUpdateWarehouse", this.warehouseForm);
        if (!res.HasError) {
          this.warehouseForm = {
            ID: null,
            WarehouseNo: null,
            AuthorizedPerson: null,
            Email: null,
            ContactNumber: null,
            CountryId: 2635167,
            CityId: null,
            PostalCode: null,
            Address: null,
            Notes: null,
            Title: null,
          };
          await this.$store.dispatch("getWarehouseList");
          this.close();
        }
        this.loading = false;
      }, 500);
    },
    async checkedId() {
      this.loading = "addUpdateScreen";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.id) {
          await this.$store.dispatch("getWarehouseList");
        } else {
          this.warehouseForm = {
            ID: null,
            WarehouseNo: null,
            AuthorizedPerson: null,
            Email: null,
            ContactNumber: null,
            CountryId: 2635167,
            CityId: null,
            PostalCode: null,
            Address: null,
            Notes: null,
            Title: null,
          };
        }

        this.loading = false;
      }, 500);
    },
  },
  computed: {
    validation() {
      return this.$store.getters.getWarehousesValidation;
    },
    getAllCountries() {
      return this.$store.getters.getAllCountries;
    },
    getCountries() {
      return this.$store.getters.getCountries;
    },
    getCities() {
      return this.$store.getters.getCities;
    },
    stockFilter() {
      return this.$store.getters.getStockFilter;
    },
    stockTotalCount() {
      return this.$store.getters.getStockTotalCount;
    },
    stockList() {
      return this.$store.getters.getStockList;
    },
    user() {
      return this.$store.getters.getUser;
    },
    warehouseList() {
      return this.$store.getters.getWarehouseList;
    },
  },
};
</script>

<style lang="less">
.innerDialog {
  width: 50% !important;
}
.el-pagination {
  .btn-prev,
  .btn-next {
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.09);
  }
  .el-pager {
    .number {
      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.09);
    }
  }
}
</style>
