<template>
  <div id="potential-customer-quotations">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Warehouse List</div>
        <div class="actions-buttons">
          <el-button type="secondary" class="btn" :class="{ 'active-filter': filterActive ? true : false }" @click="toggle"><i class="icon-filter-filter"></i> Filters</el-button>
          <el-button type="primary" class="btn" :loading="loading == 'addButton' ? true : false" @click="openAddDialog(null)" v-if="user.Role == 10 || user.Role == 3 || user.Role == 5">
            <i class="el-icon-loading" style="font-size: 18px; color: white" v-if="loading == 'openAddDialogLoading'"></i>
            <i class="icon-filter-add" v-else></i> Add New Warehouse</el-button
          >
        </div>
      </el-header>
    </portal>
    <div class="filter-container">
      <div class="header">
        <div class="title">Filters</div>
        <el-button type="danger" class="btn" @click="clearFilter">
          <i class="icon-filter-close"></i>
          Clear Filter
        </el-button>
      </div>
      <div class="all-inputs">
        <el-row :gutter="36">
          <el-col :sm="8">
            <el-input placeholder="Search" prefix-icon="el-icon-search" v-model="filter.SearchTerm" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Date</div>
            <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" v-model="dateRange" type="daterange" align="right" unlink-panels range-separator="To" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Country</div>
            <el-select
              v-model="filter.CountryId"
              filterable
              placeholder="Select"
              @clear="
                filter.CountryId = null;
                filter.CityId = null;
              "
              clearable
            >
              <el-option v-for="(item, index) in getAllCountries" :key="item.country_id + index" :label="item.country" :value="item.country_id"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">City</div>
            <el-select v-model="filter.CityId" filterable placeholder="Select" @clear="filter.CityId = null" clearable :disabled="filter.CountryId == null">
              <template v-if="getAllCountries.some((x) => x.country_id == filter.CountryId)">
                <el-option v-for="item in getAllCountries.find((x) => x.country_id == filter.CountryId).cities" :key="item.city_id" :label="item.name" :value="item.city_id"> </el-option>
              </template>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="table-container">
      <div class="view-info">
        <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p>
        <el-button class="refresh-btn" @click="arrowsRotate"> <i class="icon-arrows-rotate-solid"></i></el-button>
      </div>
      <el-table v-if="warehouseList" :data="(user.Role == 10 || user.Role==3 || user.Role==5) ? warehouseList : warehouseList.filter(x=> x.ID == user.WarehouseId)" style="width: 100%">
        <el-table-column prop="WarehouseNo" label="Warehouse ID" width="180"> </el-table-column>
        <el-table-column prop="Title" label="Warehouse Name"></el-table-column>
        <el-table-column prop="ContactNumber" label="Contact"></el-table-column>
        <el-table-column prop="Email" label="Email"></el-table-column>
        <el-table-column label="Location">
          <template slot-scope="scope">
            <span style="text-transform: none" v-if="getAllCountries.some((x) => x.country_id == scope.row.CountryId)">{{ getAllCountries.find((x) => x.country_id == scope.row.CountryId).cities.find((x) => x.city_id == scope.row.CityId).name }}, </span>
            <span style="text-transform: none" v-if="getAllCountries.some((x) => x.country_id == scope.row.CountryId)">{{ getAllCountries.find((x) => x.country_id == scope.row.CountryId).country }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date">
          <template slot-scope="scope">
            <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span
            ><br />
            <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="160">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="view-edit" @click="openAddDialog(scope.row.ID)"><i class="icon-pen-to-square-solid"></i> View & Edit</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span class="delete-item" v-if="user.Role == 10 || user.Role == 3 || user.Role == 5" @click="deleteFile(scope.row.ID)"><i class="icon-trash-can-solid"></i>Delete Item</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-bottom">
        <div class="info">
          <span>Show</span>
          <el-select v-model="filter.PageSize">
            <el-option label="12" :value="12"></el-option>
            <el-option label="24" :value="24"></el-option>
            <el-option label="36" :value="36"></el-option>
            <el-option label="100" :value="100"></el-option>
            <el-option label="200" :value="200"></el-option>
          </el-select>
          <span>entries</span>
        </div>
        <el-pagination layout="prev, pager, next" :current-page.sync="filter.Page" :page-size="filter.PageSize" :total="totalCount.TotalCount"> </el-pagination>
      </div>
    </div>
    <el-dialog custom-class="add-or-update-warehouse" top="5vh" :destroy-on-close="true" :close-on-click-modal="false" :before-close="clearValidation" :visible.sync="dialogVisible" :show-close="false">
      <addOrUpdateWarehouse :id="setId" @close="closeAddDialog()" v-if="dialogVisible"></addOrUpdateWarehouse>
    </el-dialog>
  </div>
</template>

<script>
import addOrUpdateWarehouse from "../../components/addOrUpdateWarehouse.vue";

export default {
  components: {
    addOrUpdateWarehouse,
  },
  data() {
    return {
      setId: null,
      filterActive: false,
      dialogVisible: false,
      dateRange: null,
      loading: false,
      timeout: null,
    };
  },
  beforeMount() {
    this.$store.dispatch("getWarehouseList");
    this.$store.dispatch("getStockList");
    this.$store.dispatch("getUserList");

  },
  mounted() {
    this.jQuery(".filter-container").hide();
  },
  computed: {
    stockList() {
      return this.$store.getters.getStockList;
    },
    warehouseList() {
      return this.$store.getters.getWarehouseList;
    },
    filter() {
      return this.$store.getters.getWarehousesFilter;
    },
    totalCount() {
      return this.$store.getters.getWarehousesTotalCount;
    },
    getAllCountries() {
      return this.$store.getters.getAllCountries;
    },
    form() {
      return this.$store.getters.getWarehousesForm;
    },
    user() {
      return this.$store.getters.getUser;
    },
    userList() {
      return this.$store.getters.getUserList;
    },
  },
  methods: {
    arrowsRotate() {
      this.$store.dispatch("getWarehouseList");
    },
    clearFilter() {
      this.filter.SearchTerm = null;
      this.dateRange = null;
      this.filter.CountryId = null;
      this.filter.CityId = null;
    },
    clearValidation() {
      this.$store.commit("setWarehouseValidation", []);
      this.dialogVisible = false;
    },
    openAddDialog(item) {
      if (item != null) {
        this.loading = "openAddDialogLoading" + item;
      } else {
        this.loading = "openAddDialogLoading";
      }
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.setId = item;
        this.$store.commit("setWarehouseValidation", []);
        this.dialogVisible = true;
        this.loading = false;
      }, 500);
    },
    toggle() {
      this.filterActive = !this.filterActive;
      this.jQuery(".filter-container").slideToggle();
    },
    deleteFile(Id) {
      this.$store.dispatch("deleteWarehouse", { Id });
      this.$store.dispatch("getWarehouseList");
    },
    closeAddDialog(id) {
      this.setId = id;
      this.dialogVisible = false;
    },
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        if (this.dateRange != null && this.dateRange.length > 0) {
          this.filter.StartDate = this.dateRange[0];
          this.filter.EndDate = this.dateRange[1];
        } else {
          this.filter.StartDate = null;
          this.filter.EndDate = null;
          this.dateRange = null;
        }
      },
    },
    filter: {
      deep: true,
      handler: async function () {
        await this.$store.dispatch("getWarehouseList");
      },
    },
  },
};
</script>

<style></style>
